import { Pivot, PivotItem, useTheme } from "@fluentui/react";
import { useFormikContext } from "formik";
import React, { useContext, useRef } from "react";
import { JemConfiguration } from "../../../JemConfiguration";
import { DomainDataEnum, DomainDataObjects, GLCommentsModel, LoadingStatus, UserContext } from "../../../Shared";
import { AliasChangeModal, AliasChangeModalRef } from "../../../Shared/components/AliasChangeModal/AliasChangeModal";
import { GenericDialog, GenericDialogRef } from "../../../Shared/components/GenericDialog/GenericDialog";
import { GLAttachmentEndpointMap, GLAttachments, GLAttachmentsRef } from "../../shared";
import { OnChangeChecklistOrPeriodHandlers } from "../../shared/ChecklistPeriodChangeHandler";
import { FiscalPeriodStringTransformations } from "../../utilities";
import { GLPageState } from "./GLCreate.State";
import { GLCreateTabNames } from "./GLCreate.types";
import { GLCreateFormikState } from "./GLCreateForm.types";
import { PivotItemProps, _customRenderer } from "./GLCreatePivotItems.utils";
import {
  GLCreateJeDetails,
  GLCreateRecurringJeDetails,
  GLErrorDetailsTab,
  GLPostersTab,
  GLReviewersTab
} from "./GLCreateTabs";
import { LocalAttachment, IAttachment } from "../../../Shared/components/Attachments/Attachments.types";
import { useSearchParams } from "react-router-dom";
import { EmptyRefGuid } from "../../utilities/EmptyRefGuid";

export interface GLCreatePivotItemsProps {
  configuration: Pick<JemConfiguration, "GeneralLedgerApi" | "DocumentsApi">;
  disabled: boolean;
  loadingStatus: LoadingStatus;
  currentTab: GLCreateTabNames;
  onTabChange: (item: PivotItem) => void;
  initialState: GLPageState;
  postersInitialState: GLCommentsModel[];
  domainData: Pick<
    DomainDataObjects,
    | DomainDataEnum.JeCompanyCodes
    | DomainDataEnum.JeTypes
    | DomainDataEnum.JeReasonCodes
    | DomainDataEnum.CurrencyCodes
    | DomainDataEnum.FiscalPeriods
    | DomainDataEnum.StatusActions
    | DomainDataEnum.RecurringScheduleFrequency
    | any
  >;
  attachmentsRef: React.Ref<GLAttachmentsRef>;
  onUploadFile: GLAttachmentEndpointMap["uploadAttachment"];
  onDeleteFile: GLAttachmentEndpointMap["deleteAttachment"];
  onDownloadFile: GLAttachmentEndpointMap["downloadAttachment"];
  hasMsSalesPermission: boolean;
  openHistoryPanel?: (guid: string | number) => void;
}

const GLCreatePivotItems: React.FC<GLCreatePivotItemsProps> = (props) => {
  const theme = useTheme();
  const formik = useFormikContext<GLCreateFormikState>();
  const genericDialogRef = useRef<GenericDialogRef>(null);
  const aliasChangeModalRef = useRef<AliasChangeModalRef>(null);
  const userContext = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const templateId = searchParams.get("Templateid");

  const fyfp = FiscalPeriodStringTransformations.FiscalPeriodStringToFiscalYearAndFiscalMonth(
    formik.values.detailsTabFiscalYearPeriod
  );

  return (
    <>
      <Pivot
        aria-label="New JE Input Sections"
        selectedKey={props.currentTab}
        onLinkClick={(item?: PivotItem | undefined) => {
          if (item) {
            props.onTabChange(item);
          }
        }}
        styles={{
          root: {
            backgroundColor: theme.palette.neutralLighterAlt,
            ":after": {
              content: "''",
              height: "1px",
              display: "block",
              width: "100%",
              backgroundColor: theme.palette.neutralTertiaryAlt
            }
          }
        }}
      >
        <PivotItem headerText="JE Details" itemKey={GLCreateTabNames.jeDetails} alwaysRender={true}>
          <div {...PivotItemProps}>
            <GLCreateJeDetails
              pageState={props.initialState}
              disabled={props.disabled}
              loadingStatus={props.loadingStatus}
              domainData={props.domainData}
              configuration={props.configuration.GeneralLedgerApi}
              {...OnChangeChecklistOrPeriodHandlers(
                props.domainData,
                formik,
                false,
                genericDialogRef,
                aliasChangeModalRef
              )}
              hasMsSalesPermission={props.hasMsSalesPermission}
            />
          </div>
        </PivotItem>
        {userContext.jemUser.showRecurringJE &&
          templateId !== "4" &&
          (props.initialState.createState.jeDetails.checklistRefGuid &&
          props.initialState.createState.jeDetails.checklistRefGuid !== EmptyRefGuid()
            ? props.initialState.createState.jeDetails.isAdhocDraft
            : true) && (
            <PivotItem
              headerText="Recurring JE Schedule"
              itemKey={GLCreateTabNames.recurringJeSchedule}
              alwaysRender={true}
            >
              <div {...PivotItemProps}>
                <GLCreateRecurringJeDetails
                  pageState={props.initialState}
                  disabled={props.disabled}
                  loadingStatus={props.loadingStatus}
                  domainData={props.domainData}
                  configuration={props.configuration.GeneralLedgerApi}
                  openHistoryPanel={props?.openHistoryPanel}
                  {...OnChangeChecklistOrPeriodHandlers(
                    props.domainData,
                    formik,
                    false,
                    genericDialogRef,
                    aliasChangeModalRef
                  )}
                />
              </div>
            </PivotItem>
          )}
        <PivotItem
          headerText={`Attachments(${formik.values.attachments_count})`}
          itemKey={GLCreateTabNames.attachments}
          alwaysRender={true}
        >
          <div {...PivotItemProps}>
            <GLAttachments
              customRef={props.attachmentsRef}
              initialAttachments={props.initialState.createState.attachments}
              onDeleteFile={props.onDeleteFile} //attachmentActions.deleteAttachment}
              onUploadFile={props.onUploadFile} //attachmentActions.uploadAttachment}
              onDownloadFile={props.onDownloadFile} //attachmentActions.downloadAttachment}
              disabled={props.disabled}
              loadingStatus={props.loadingStatus}
              initialRegion={props.initialState.createState.attachments_region.toString()}
              lockRegion={props.initialState.createState.lock_region}
              onChange={(
                localAttachments: LocalAttachment[],
                remoteAttachments: IAttachment[],
                errorMessage: string | null
              ) => {
                const newCount = localAttachments.length + remoteAttachments.length;
                formik.setFieldValue("attachments_count", newCount);
                if (errorMessage) {
                  formik.setFieldValue("attachments_error", errorMessage);
                }
              }}
              items={[
                {
                  fiscalPeriod: fyfp.fiscalMonth.toString(),
                  fiscalYear: fyfp.fiscalYear.toString(),
                  refGuid: props.initialState.pageConfiguration.refGuid,
                  jeId: undefined,
                  format: "draft"
                }
              ]}
            />
          </div>
        </PivotItem>
        <PivotItem headerText="Posters" itemKey={GLCreateTabNames.posters} alwaysRender={true}>
          <div {...PivotItemProps}>
            <GLPostersTab
              comments={props.postersInitialState}
              disabled={props.disabled}
              loadingStatus={props.loadingStatus}
              isF05Posting={props.initialState.pageConfiguration.templateId === "4"}
            />
          </div>
        </PivotItem>
        <PivotItem headerText="Reviewers" itemKey={GLCreateTabNames.reviewers} alwaysRender={true}>
          <div {...PivotItemProps}>
            <GLReviewersTab disabled={props.disabled} loadingStatus={props.loadingStatus} />
          </div>
        </PivotItem>

        <PivotItem
          headerText="Error Details"
          itemKey={GLCreateTabNames.errorDetails}
          onRenderItemLink={_customRenderer}
          itemIcon={
            !props.disabled &&
            (formik.values.genericHeaderErrors.length !== 0 ||
              Object.keys(formik.errors).length !== 0 ||
              formik.values.errorTabErrorTable.length !== 0)
              ? "Error"
              : undefined
          }
        >
          <div {...PivotItemProps}>
            <GLErrorDetailsTab
              loadingStatus={props.loadingStatus}
              errors={formik.errors}
              genericHeaderErrors={formik.values.genericHeaderErrors}
              lineItemErrors={formik.values.errorTabErrorTable}
            />
          </div>
        </PivotItem>
      </Pivot>
      <GenericDialog customRef={genericDialogRef} />
      <AliasChangeModal customRef={aliasChangeModalRef} />
    </>
  );
};

export default GLCreatePivotItems;
