/* eslint-disable @typescript-eslint/no-unused-vars */
import { CoherencePanel, CoherencePanelSize } from "@coherence-design-system/controls";
import React, { useEffect, useState } from "react";
import InlineFormInputErrorMessage from "./InlineFormInputErrorMessage";
import {
  ApiError,
  DashboardGrid,
  getRequest,
  getValidUrl,
  IExtendedColumn,
  IUserProviderState,
  JemConfiguration,
  LoadingSpinner
} from "@jem/components";
import { SelectionMode, Text } from "@fluentui/react";

interface IRecurrignAuditHistoryResponse {
  fieldName: string;
  oldValue: string;
  newValue: string;
}

interface RecurringScheduleAuditCols extends IExtendedColumn {
  fieldName: keyof IRecurrignAuditHistoryResponse;
}

async function getHistoryPanelData(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"],
  scheduleId: string | number
): Promise<IRecurrignAuditHistoryResponse[]> {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.recurringAuditHistory}`.replace(
    "{recurringScheduleId}",
    "" + scheduleId
  );

  const endpointUrl = getValidUrl(endpoint);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const response = await getRequest<any>(endpointUrl, getTokenFn);

  if (!response) {
    throw new ApiError("No response from server");
  }

  return response;
}

const getScheduleAuditColumns = () => (): IExtendedColumn[] => {
  const cols: RecurringScheduleAuditCols[] = [
    {
      key: "fieldName",
      name: "Field Name",
      fieldName: "fieldName",
      type: "string",
      minWidth: 140
    },
    {
      key: "oldValue",
      name: "Old Value",
      fieldName: "oldValue",
      type: "string",
      minWidth: 140
    },
    {
      key: "newValue",
      name: "New Value",
      fieldName: "newValue",
      type: "string",
      minWidth: 140
    }
  ];

  return cols;
};

interface IGLCreateRecurringJeHistoryPanelProps {
  configuration: JemConfiguration["GeneralLedgerApi"];
  userContext: IUserProviderState;
  onClosePanel: () => void;
  loading?: boolean;
  historyPanelScheduleId: string | number;
  isOpen: boolean;
}

const GLCreateRecurringJeHistoryPanel = (props: IGLCreateRecurringJeHistoryPanelProps): JSX.Element => {
  const { onClosePanel, userContext, configuration, historyPanelScheduleId, isOpen } = props;
  const [errors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [gridItems, setGridItems] = useState<IRecurrignAuditHistoryResponse[]>([]);

  useEffect(() => {
    setIsLoading(true);
    const getData = async () => {
      const result = await getHistoryPanelData(configuration, userContext.accessToken, historyPanelScheduleId);
      setGridItems(result);
      setIsLoading(false);
    };
    getData();
  }, []);

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.large}
      titleText={"History Panel"}
      isOpen={isOpen}
      onDismiss={onClosePanel}
      hasCloseButton
      closeButtonAriaLabel="Close History Panel"
    >
      <InlineFormInputErrorMessage errorMessages={errors} />
      {isLoading ? (
        <LoadingSpinner label={"Loading..."} />
      ) : (
        <>
          {gridItems.length ? (
            <DashboardGrid
              idForLocalStorage={"glHistoryPanelData"}
              customRef={null}
              columnGenerator={getScheduleAuditColumns()}
              items={gridItems}
              height={`300px`}
              disablePagination={true}
              disableSorting={true}
              selectionMode={SelectionMode.none}
            ></DashboardGrid>
          ) : (
            <Text
              variant="xLarge"
              style={{
                color: "var(--accent-font-color, black)"
              }}
            >
              No Items to show.
            </Text>
          )}
        </>
      )}
    </CoherencePanel>
  );
};
export default GLCreateRecurringJeHistoryPanel;
