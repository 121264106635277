﻿import { css } from "@emotion/css";
import {
  Link,
  Text,
  Selection,
  MessageBar,
  MessageBarType,
  DefaultButton,
  PrimaryButton,
  Stack
} from "@fluentui/react";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { LoadingSpinner } from "../../../Shared/components/LoadingSpinner/LoadingSpinner";
import { DEFAULT_NULL_CHARACTER } from "../../../Shared/utilities/ApiParsingUtilities";
import { IUserProviderState, MockDataFn } from "../../../Shared/utilities/RequestUtilities";
import { LoadingStatus } from "../../../Shared/utilities/Utilities";

import { getAutomatedDashboardColumns } from "./AutomatedJeDashboard.columns";
import { filterRows } from "./AutomatedJeDashboard.filter";
import {
  AutomatedDashboardConfiguration,
  AutomatedJeDashboardElement,
  cancelAutomatedJeDashboardItems,
  getAutomatedJeDashboardData
} from "./AutomatedJeDashboard.requests";
import { DashboardFilter } from "../../../Shared/components/DashboardFilter/DashboardFilter";
import {
  DashboardSourceSelector,
  IDashboardSourceSelectorRef,
  ISourceSelectorState,
  ITenantProp
} from "../../../Shared/components/DashboardSourceSelector/DashboardSourceSelector";
import {
  DashboardListActions,
  IDashboardListActionsState
} from "../../../Shared/components/DashboardListActions/DashboardListActions";
import { IDashboardGridRef, UserConfiguration } from "../../../Shared/components/DashboardGrid/DashboardGrid.types";
import {
  DashboardListActionsRef,
  GridCommandToggle
} from "../../../Shared/components/DashboardListActions/DashboardListActions.types";
import { S2SActionsManager, IActionsManager } from "../S2SActionsManager/S2SActionsManager";
import { sortedBySubmittedTime } from "./AutomatedJeDashboard.utilities";
import {
  NotificationsManager,
  NotificationMessagesRef,
  Notification
} from "../../../Shared/components/NotificationsManager/NotificationsManager";
import { WeightageCalculator } from "../../../Shared/utilities/WeightageCalculator";
import { DashboardGrid } from "../../../Shared/components/DashboardGrid/DashboardGrid";
import { FilterState, IDashboardTileRef } from "../../../Shared/components/DashboardFilter/DashboardFilter.types";
import { JeDetailsManager, IJeDetailsManager } from "../JeDetailsManager/JeDetailsManager";
import { exportToExcel } from "../../../Shared/components/DashboardGrid/DashboardGrid.utilities";
import { DomainDataEnum, DomainDataObjects, JEMContext, UserContext } from "../../../Shared";
import { ITenantConfigItemDisplay } from "./CollapsibleList";

const onRenderDocumentNumberFn = function (jeDetailsRef: React.RefObject<IJeDetailsManager["ref"]>) {
  const fn = (gridItem: AutomatedJeDashboardElement): JSX.Element => {
    if (gridItem.jeDocNum === DEFAULT_NULL_CHARACTER) {
      return <span>{DEFAULT_NULL_CHARACTER}</span>;
    }
    return (
      <Link
        onClick={() => {
          if (jeDetailsRef.current) {
            jeDetailsRef.current.open(gridItem);
          }
        }}
      >
        {gridItem.jeDocNum}
      </Link>
    );
  };
  return fn;
};

const dashboardUserConfigName = "automatedJeDashboardConfiguration";

export interface ITenantConfigItem {
  key: string;
  name: string;
  primaryPoster: string;
  additionalPosters: string;
  defaultReviewer: string;
  tenantOwner: string;
}

export interface IAutomatedJeDashboardProps {
  tenants: ITenantProp[];
  configuration: AutomatedDashboardConfiguration;
  getTokenFn?: IUserProviderState["accessToken"];
  mockFn?: MockDataFn<AutomatedJeDashboardElement[]>;
  mockFetch?: (input: RequestInfo) => Promise<{
    json: () => Promise<any>;
  }>;
  updatedDate: (date: string) => void;
  editTenant: (appId: string) => void;
}

interface ClearResponse {
  status: string;
  message: string;
}

function calculateButtonStates(rows: AutomatedJeDashboardElement[]): IDashboardListActionsState {
  let initialState: IDashboardListActionsState = {
    retryDisabled: GridCommandToggle.Disabled,
    saveDisabled: GridCommandToggle.Disabled,
    approveDisabled: GridCommandToggle.Disabled,
    releaseForSignoffDisabled: GridCommandToggle.Disabled,
    needsClarificationDisabled: GridCommandToggle.Disabled,
    addPosterDisabled: GridCommandToggle.Disabled,
    addReviewerDisabled: GridCommandToggle.Disabled,
    recallDisabled: GridCommandToggle.Disabled,
    rescindDisabled: GridCommandToggle.Disabled,
    addAttachmentDisabled: GridCommandToggle.Disabled,
    deleteActionDisabled: GridCommandToggle.Disabled,
    releaseToSAPDisabled: GridCommandToggle.Removed,
    sendBackFromTreasuryDisabled: GridCommandToggle.Removed,
    sendToPosterDisabled: GridCommandToggle.Removed,
    clearDisabled: GridCommandToggle.Disabled
  };

  initialState = checkRetryButtonState(rows, initialState);
  initialState = checkCancelButtonState(rows, initialState);
  return initialState;
}

function checkRetryButtonState(
  rows: AutomatedJeDashboardElement[],
  initialState: IDashboardListActionsState
): IDashboardListActionsState {
  for (const row of rows) {
    const retryable = WeightageCalculator.isRetryButtonEnabled({ Retry: 2 }, { rowWeightage: row.rowWeightage });
    if (retryable) {
      initialState.retryDisabled = GridCommandToggle.Enabled;
    } else {
      initialState.retryDisabled = GridCommandToggle.Disabled;
      // if one is disabled, then all must be disabled
      // bug: https://microsoftit.visualstudio.com/OneITVSO/_sprints/taskboard/FIN-STR-GL-JEM/OneITVSO/2107/2107-2?workitem=7733037
      return initialState;
    }
  }
  return initialState;
}

function checkCancelButtonState(
  rows: AutomatedJeDashboardElement[],
  initialState: IDashboardListActionsState
): IDashboardListActionsState {
  for (const row of rows) {
    if (row.showClearButton && row.postingType == "Regular") {
      initialState.clearDisabled = GridCommandToggle.Enabled;
    } else {
      initialState.clearDisabled = GridCommandToggle.Disabled;
      // if one is disabled, then all must be disabled
      // bug: https://microsoftit.visualstudio.com/OneITVSO/_sprints/taskboard/FIN-STR-GL-JEM/OneITVSO/2107/2107-2?workitem=7733037
      return initialState;
    }
  }
  return initialState;
}

const AutomatedJeDashboard: React.FC<IAutomatedJeDashboardProps> = (props) => {
  const tilesRef = useRef<IDashboardTileRef<AutomatedJeDashboardElement>>(null);
  const selectorRef = useRef<IDashboardSourceSelectorRef>(null);
  const jeDetailsRef = useRef<IJeDetailsManager["ref"]>(null);
  const actionManagerRef = useRef<IActionsManager["ref"]>(null);
  const dashboardGridRef = useRef<IDashboardGridRef>(null);
  const dashboardActionsRef = useRef<DashboardListActionsRef>(null);
  const notificationManagerRef = useRef<NotificationMessagesRef>(null);

  const jemContext = useContext(JEMContext);
  const userContext = useContext(UserContext);
  const tenantConfigs = jemContext?.initInfo?.values
    ?.JemTenantConfigs as DomainDataObjects[DomainDataEnum.JemTenantConfigs];

  const [dashboardGridStatus, setDashboardGridStatus] = useState<LoadingStatus>(LoadingStatus.Idle);

  const [gridItems, setGridItems] = useState<AutomatedJeDashboardElement[]>([]);
  const [filteredItems, setFilteredItems] = useState<AutomatedJeDashboardElement[]>([]);
  const [showTenantDetailsList, setShowTenantDetailsList] = useState(false);
  const [selectedTenantDetails, setSelectedTenantDetails] = useState<ITenantConfigItemDisplay[]>([]);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [messageDetails, setMessageDetails] = useState<ClearResponse | null>(null);

  const handleDashboardSelectorRef = (selectorState: ISourceSelectorState) => {
    setShowTenantDetailsList(false);
    const tenantDetails = tenantConfigs?.find(
      (tenant) => tenant.appId.toLowerCase() === selectorState.appId.toLowerCase()
    );
    if (tenantDetails) {
      const tenantConfigItem: ITenantConfigItem = {
        key: tenantDetails.appId,
        name: tenantDetails.name,
        primaryPoster: tenantDetails.primaryPoster,
        additionalPosters: tenantDetails.additionalPosters || "",
        defaultReviewer: tenantDetails.defaultReviewers,
        tenantOwner: tenantDetails.tenantOwner
      };
      const tenantConfigItemDisplay: ITenantConfigItemDisplay[] = [];
      for (const [propertyName, propertyValue] of Object.entries(tenantConfigItem)) {
        tenantConfigItemDisplay.push({ column: propertyName, value: propertyValue });
      }

      setSelectedTenantDetails(tenantConfigItemDisplay);
      setShowTenantDetailsList(true);
    }
  };

  const [dashboardUserConfiguration, setDashboardUserConfiguration] = useState<null | undefined | UserConfiguration>(
    undefined
  );
  useEffect(() => {
    const lDashboardConfig = localStorage.getItem(dashboardUserConfigName);
    if (lDashboardConfig) {
      setDashboardUserConfiguration(JSON.parse(lDashboardConfig));
    } else {
      setDashboardUserConfiguration(null);
    }
  }, []);

  useEffect(() => {
    let mounted = true;
    setDashboardGridStatus(LoadingStatus.Pending);
    const getGrid = async () => {
      try {
        if (selectorRef.current) {
          handleDashboardSelectorRef(selectorRef.current.getState());
          const items = await getAutomatedJeDashboardData(selectorRef.current.getState(), props.configuration, {
            mockFn: props.mockFn,
            getTokenFn: props.getTokenFn
          });
          if (mounted) {
            const gridData = sortedBySubmittedTime(items);

            setGridItems(gridData);
            setFilteredItems(gridData);
            tilesRef.current?.refreshTilesAndFilters(gridData as any);
            setDashboardGridStatus(LoadingStatus.Resolved);
          }
        }
      } catch (err) {
        setDashboardGridStatus(LoadingStatus.Rejected);
      }
    };
    if (props.tenants && props.tenants.length !== 0) {
      getGrid();
    }
    return () => {
      mounted = false;
    };
  }, [props.tenants]);

  const handleNotifications = (notifications: Notification[]) => {
    if (notificationManagerRef.current) {
      notificationManagerRef.current.open(notifications);
    }
  };

  const refreshTriggerFn = async (selectorState: ISourceSelectorState) => {
    setDashboardGridStatus(LoadingStatus.Pending);
    try {
      if (selectorRef.current) {
        handleDashboardSelectorRef(selectorState);
        const items = await getAutomatedJeDashboardData(selectorState, props.configuration, {
          mockFn: props.mockFn,
          getTokenFn: props.getTokenFn
        });
        const gridData = sortedBySubmittedTime(items);
        setGridItems(gridData);
        setFilteredItems(gridData);
        tilesRef.current?.refreshTilesAndFilters(gridData);
        setDashboardGridStatus(LoadingStatus.Resolved);
        props.updatedDate(new Date().toLocaleString("en-US"));
      }
    } catch (err) {
      setDashboardGridStatus(LoadingStatus.Rejected);
    }
  };

  const filterData = useCallback(
    (filterState?: FilterState) => {
      if (filterState) {
        const newItems = filterRows(filterState, gridItems);
        setFilteredItems(newItems);
      }
    },
    [gridItems]
  );

  if (props.tenants === undefined) {
    return <LoadingSpinner label="Loading AutomatedJe Dashboard" />;
  }
  if (props.tenants.length === 0) {
    return <Text variant="xLarge">You do not have access to any tenants.</Text>;
  }

  // const onRenderCell = (item?: ITenantConfigItemDisplay): JSX.Element => {
  //   const rclassNames = {
  //     itemContent: css`
  //       display: inline-block;
  //       border: 1px solid #ccc;
  //       padding: 4px;
  //       margin-right: 8px;
  //       display: flex;
  //       align-items: center;
  //     `,
  //     itemName: css`
  //       font-weight: bold;
  //       margin-right: 4px;
  //       font-size: 0.9em;
  //       width: 100px;
  //     `,
  //     itemIndex: css`
  //       margin-top: 4px;
  //       margin-left: 2px;
  //       border-left: 1px solid #ccc;
  //       padding-top: 4px;
  //       padding-left: 4px;
  //       font-size: 0.9em;
  //     `
  //   };

  //   return (
  //     <div className={rclassNames.itemContent}>
  //       <div className={rclassNames.itemName}>{item?.column}</div>
  //       <div className={rclassNames.itemIndex}>{item?.value}</div>
  //     </div>
  //   );
  // };

  return (
    <div
      className={css`
        position: relative;
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        grid-template-rows: auto;
        & > * {
          grid-row: 1;
          grid-column: 1;
        }
        & .content {
          width: 100%;
        }
        & > [data-state="open"] ~ .content {
          pointer-events: none;
          visibility: hidden;
          opacity: 0;
        }
      `}
    >
      <NotificationsManager ref={notificationManagerRef}></NotificationsManager>
      <S2SActionsManager
        ref={actionManagerRef}
        configuration={props.configuration}
        getTokenFn={props.getTokenFn}
      ></S2SActionsManager>
      <JeDetailsManager ref={jeDetailsRef} configuration={props.configuration} getTokenFn={props.getTokenFn} />
      <div className="content">
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Link href="https://aka.ms/jemtenantdoc" target="_blank">
            Help Documentation
          </Link>
        </div>
        <DashboardSourceSelector
          ref={selectorRef}
          triggerRefreshFn={refreshTriggerFn}
          tenants={props.tenants}
        ></DashboardSourceSelector>
        {/* {showTenantDetailsList && <CollapsibleList tenantDisplayableDetails={selectedTenantDetails} />} */}
        <Link
          onClick={() => {
            props.editTenant(selectedTenantDetails.filter((dtls) => dtls.column == "name")[0].value);
          }}
          style={{ fontSize: "16px", textDecoration: "underline", marginBotton: "5px" }}
        >
          View/Edit Tenant
        </Link>
        <br />
        <Text
          className={css`
            font-size: 18px;
            line-height: 1.5em;
          `}
          variant="large"
        >
          Journal entries
        </Text>
        <div style={{ position: "relative" }}>
          <DashboardFilter<AutomatedJeDashboardElement>
            filters={[
              {
                columnName: "jeStatus",
                filterName: "Status"
              },
              {
                columnName: "dueDate",
                filterName: "Due date"
              },
              {
                columnName: "companyCode",
                filterName: "Co. Code"
              },
              {
                columnName: "jeType",
                filterName: "JE Type"
              }
            ]}
            singleSelectionFilters={["Status"]}
            searchEnabledFilters={["Co. Code", "JE Type"]}
            customRef={tilesRef}
            onFilterChanged={filterData}
            loadingStatus={dashboardGridStatus}
          />
          {showMessage ? (
            <MessageBar
              delayedRender={false}
              messageBarType={messageDetails?.status === "Success" ? MessageBarType.success : MessageBarType.error}
              dismissButtonAriaLabel="Close"
              onDismiss={() => {
                setMessageDetails(null);
                setShowMessage(false);
              }}
            >
              {messageDetails?.message}
            </MessageBar>
          ) : null}
          {gridItems.length === 0 ? (
            <Text
              variant="xLarge"
              style={{
                color: "var(--accent-font-color, gray)",
                textAlign: "center"
              }}
            >
              No Items to show.
            </Text>
          ) : dashboardGridStatus === LoadingStatus.Resolved && dashboardUserConfiguration !== undefined ? (
            <DashboardGrid
              idForLocalStorage="automatedJeDashboardConfiguration"
              columnGenerator={getAutomatedDashboardColumns(onRenderDocumentNumberFn(jeDetailsRef))}
              customRef={dashboardGridRef}
              items={filteredItems}
              isSortedIndex={3}
              height={`${Math.min((gridItems.length + 1) * 43 + 42, 672)}px`}
              isSortedDescending={true}
              onSelectionChanged={(selection: Selection) => {
                if (dashboardActionsRef.current) {
                  const userSelectedItems = selection.getSelection();
                  dashboardActionsRef.current.refreshSelection(userSelectedItems as AutomatedJeDashboardElement[]);
                }
              }}
              onExport={(rows) => {
                exportToExcel({
                  sheetName: "s2sEntries",
                  rowsWithHeader: rows,
                  fileName: "automatedJeDashboard.xlsx"
                });
              }}
            >
              <DashboardListActions
                customRef={dashboardActionsRef}
                preventCollapse={true}
                buttonCalculator={calculateButtonStates}
                hide={false}
                retry={() => {
                  // open action manager with name = retry, and the entries
                  if (actionManagerRef.current && dashboardGridRef.current) {
                    const selection = dashboardGridRef.current.getSelection();
                    const items = selection.items as AutomatedJeDashboardElement[];
                    actionManagerRef.current.open("Retry", items);
                    const onActionFinished = actionManagerRef.current.getOnSubmitAsyncSubject();
                    onActionFinished.subscribe((notifications) => {
                      if (dashboardGridRef.current && notifications && selectorRef.current) {
                        dashboardGridRef.current.clearSelection();
                        handleNotifications(notifications);
                        refreshTriggerFn(selectorRef.current.getState());
                      }
                    });
                  }
                }}
                clear={async () => {
                  if (actionManagerRef.current && dashboardGridRef.current) {
                    const selection = dashboardGridRef.current.getSelection();
                    const items = selection.items as AutomatedJeDashboardElement[];
                    const clearRefGuids = items.map((item) => item.refguid);
                    const response: ClearResponse = await cancelAutomatedJeDashboardItems(
                      props.configuration,
                      userContext.accessToken,
                      clearRefGuids
                    );
                    setShowMessage(true);
                    setMessageDetails(response);
                  }
                }}
                retryDisabled={GridCommandToggle.Disabled}
                saveDisabled={GridCommandToggle.Removed}
                approveDisabled={GridCommandToggle.Removed}
                releaseForSignoffDisabled={GridCommandToggle.Removed}
                needsClarificationDisabled={GridCommandToggle.Removed}
                addPosterDisabled={GridCommandToggle.Removed}
                addReviewerDisabled={GridCommandToggle.Removed}
                recallDisabled={GridCommandToggle.Removed}
                rescindDisabled={GridCommandToggle.Removed}
                addAttachmentDisabled={GridCommandToggle.Removed}
                deleteActionDisabled={GridCommandToggle.Removed}
                clearDisabled={GridCommandToggle.Disabled}
              ></DashboardListActions>
            </DashboardGrid>
          ) : (
            <LoadingSpinner label="Loading grid" />
          )}
        </div>
      </div>
    </div>
  );
};

export { AutomatedJeDashboard };
