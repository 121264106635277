import { FormikProps } from "formik";
import { ApiParsingUtilities } from "../../../Shared";
import { LineItemModel, ErrorListModel, GLCreateFormikState } from "./GLCreateForm.types";
import { LineItemsSettings } from "./GLCreateLineItems/GLCreateLineItems.utilities";

export enum LineAmountOptions {
  LineAmount = "LineAmount",
  LineAmountInCurrency = "LineAmountInCurrency",
  Both = "Both",
  FourCurrency = "FourCurrency"
}

export const HardCurrencies = ["1233", "1234"];
export type PickedLineItemModel = Pick<
  LineItemModel,
  "lineAmount" | "lineAmountInCurrency" | "lineAmountLC2" | "lineAmountLC3"
>;
export type PickedLineItemsSettings = Pick<LineItemsSettings, "precision">;

const defaultLineAmountFormat = 2;
export function calculateLineAmounts(
  items: [PickedLineItemModel[], ErrorListModel[]],
  columnName: LineAmountOptions,
  lineItemsSettings: PickedLineItemsSettings
) {
  let auditDebitInfo = 0.0;
  let auditCreditInfo = 0.0;
  let auditDebitLCInfo = 0.0;
  let auditCreditLCInfo = 0.0;
  let auditDebitLC2Info = 0.0;
  let auditCreditLC2Info = 0.0;
  let auditDebitLC3Info = 0.0;
  let auditCreditLC3Info = 0.0;
  const allLineItems = items[0];

  for (const lineItem of allLineItems) {
    if (
      columnName === LineAmountOptions.LineAmount ||
      columnName === LineAmountOptions.Both ||
      columnName === LineAmountOptions.FourCurrency
    ) {
      if (lineItem.lineAmount < 0) {
        auditCreditInfo += lineItem.lineAmount as number;
      } else {
        auditDebitInfo += lineItem.lineAmount as number;
      }
    }
    if (
      (columnName === LineAmountOptions.LineAmountInCurrency ||
        columnName === LineAmountOptions.Both ||
        columnName === LineAmountOptions.FourCurrency) &&
      lineItem.lineAmountInCurrency
    ) {
      // if lineAmount < 0 it is credit, otherwise it is debit
      if (lineItem.lineAmountInCurrency < 0) {
        auditCreditLCInfo += lineItem.lineAmountInCurrency as number;
      } else {
        auditDebitLCInfo += lineItem.lineAmountInCurrency as number;
      }
    }
    if (columnName === LineAmountOptions.FourCurrency && lineItem.lineAmountLC2) {
      if (lineItem.lineAmountLC2 < 0) {
        auditCreditLC2Info += lineItem.lineAmountLC2 as number;
      } else {
        auditDebitLC2Info += lineItem.lineAmountLC2 as number;
      }
    }

    if (columnName === LineAmountOptions.FourCurrency && lineItem.lineAmountLC3) {
      if (lineItem.lineAmountLC3 < 0) {
        auditCreditLC3Info += lineItem.lineAmountLC3 as number;
      } else {
        auditDebitLC3Info += lineItem.lineAmountLC3 as number;
      }
    }
  }

  const tolerance = Math.pow(10, -lineItemsSettings.precision);
  const LC2LC3tolerance = Math.pow(10, -defaultLineAmountFormat);

  let total = Number((auditDebitInfo + auditCreditInfo).toFixed(lineItemsSettings.precision));
  if (Math.abs(total) < tolerance) {
    total = 0;
  }

  let totalLC = Number((auditDebitLCInfo + auditCreditLCInfo).toFixed(lineItemsSettings.precision));
  if (Math.abs(totalLC) < tolerance) {
    totalLC = 0;
  }

  let totalLC2 = Number((auditDebitLC2Info + auditCreditLC2Info).toFixed(defaultLineAmountFormat));
  if (Math.abs(totalLC2) < LC2LC3tolerance) {
    totalLC2 = 0;
  }

  let totalLC3 = Number((auditDebitLC3Info + auditCreditLC3Info).toFixed(defaultLineAmountFormat));
  if (Math.abs(totalLC3) < LC2LC3tolerance) {
    totalLC3 = 0;
  }

  return {
    auditDebitInfo,
    auditCreditInfo,
    auditDebitLCInfo,
    auditCreditLCInfo,
    auditDebitLC2Info,
    auditCreditLC2Info,
    auditDebitLC3Info,
    auditCreditLC3Info,
    total,
    totalLC,
    totalLC2,
    totalLC3
  };
}

export function LineAmountCalculation(
  items: [PickedLineItemModel[], ErrorListModel[]],
  columnName: LineAmountOptions,
  formik: FormikProps<GLCreateFormikState>,
  lineItemsSettings: PickedLineItemsSettings
) {
  const calculatedResults = calculateLineAmounts(items, columnName, lineItemsSettings);

  const {
    auditDebitInfo,
    auditCreditInfo,
    auditDebitLCInfo,
    auditCreditLCInfo,
    auditDebitLC2Info,
    auditCreditLC2Info,
    auditDebitLC3Info,
    auditCreditLC3Info,
    total,
    totalLC,
    totalLC2,
    totalLC3
  } = calculatedResults;

  formik.setFieldValue("detailsTabCredit", ApiParsingUtilities.toDecimal(auditCreditInfo, lineItemsSettings.precision));
  formik.setFieldValue("detailsTabDebit", ApiParsingUtilities.toDecimal(auditDebitInfo, lineItemsSettings.precision));
  formik.setFieldValue(
    "detailsTabCreditLC",
    ApiParsingUtilities.toDecimal(auditCreditLCInfo, lineItemsSettings.precision)
  );
  formik.setFieldValue(
    "detailsTabDebitLC",
    ApiParsingUtilities.toDecimal(auditDebitLCInfo, lineItemsSettings.precision)
  );
  formik.setFieldValue("detailsTabTotal", ApiParsingUtilities.toDecimal(total, lineItemsSettings.precision));

  formik.setFieldValue(
    "detailsTabCreditLC2",
    ApiParsingUtilities.toDecimal(auditCreditLC2Info, defaultLineAmountFormat)
  );
  formik.setFieldValue("detailsTabDebitLC2", ApiParsingUtilities.toDecimal(auditDebitLC2Info, defaultLineAmountFormat));
  formik.setFieldValue(
    "detailsTabCreditLC3",
    ApiParsingUtilities.toDecimal(auditCreditLC3Info, defaultLineAmountFormat)
  );
  formik.setFieldValue("detailsTabDebitLC3", ApiParsingUtilities.toDecimal(auditDebitLC3Info, defaultLineAmountFormat));

  formik.setFieldValue("detailsTabTotalLC", ApiParsingUtilities.toDecimal(totalLC, lineItemsSettings.precision));
  formik.setFieldValue("detailsTabTotalLC2", ApiParsingUtilities.toDecimal(totalLC2, defaultLineAmountFormat));
  formik.setFieldValue("detailsTabTotalLC3", ApiParsingUtilities.toDecimal(totalLC3, defaultLineAmountFormat));

  const tolerance = 1 / Math.pow(10, lineItemsSettings.precision);
  const LC2LC3tolerance = 1 / Math.pow(10, defaultLineAmountFormat);
  if (Math.abs(total) < tolerance) {
    formik.setFieldError("detailsTabTotal", undefined);
  } else {
    formik.setFieldError("detailsTabTotal", "Total Debit and Credit must be equal.");
  }

  if (Math.abs(totalLC) < tolerance) {
    formik.setFieldError("detailsTabTotalLC", undefined);
  } else {
    formik.setFieldError("detailsTabTotalLC", "Total DebitLC and CreditLC must be equal.");
  }

  if (Math.abs(totalLC2) < LC2LC3tolerance) {
    formik.setFieldError("detailsTabTotalLC2", undefined);
  } else {
    formik.setFieldError("detailsTabTotalLC2", "Total DebitLC2 and CreditLC2 must be equal.");
  }

  if (Math.abs(totalLC3) < LC2LC3tolerance) {
    formik.setFieldError("detailsTabTotalLC3", undefined);
  } else {
    formik.setFieldError("detailsTabTotalLC3", "Total DebitLC3 and CreditLC3 must be equal.");
  }
}
