import React, { useContext, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from "react";
import { CommandBar } from "@fluentui/react";
import { GridCommandToggle, DashboardListActionsProps } from "./DashboardListActions.types";
import { css } from "@emotion/css";
import { CommandBarItems, getCommandBarButtons, OptionsToResize } from "./DashboardListActions.commandBarButtons";

import { debounce } from "../../utilities/Utilities";
import { PageContext } from "../../contexts/PageContext/PageContext";
import { ObjectKeys } from "../../utilities/TypeUtils";

// eslint-disable-next-line
const isEqual = require("lodash/isEqual");

export interface IDashboardListActionsState {
  retryDisabled: GridCommandToggle;
  saveDisabled: GridCommandToggle;
  sendToPosterDisabled: GridCommandToggle;
  approveDisabled: GridCommandToggle;
  releaseForSignoffDisabled: GridCommandToggle;
  needsClarificationDisabled: GridCommandToggle;
  addPosterDisabled: GridCommandToggle;
  addReviewerDisabled: GridCommandToggle;
  recallDisabled: GridCommandToggle;
  rescindDisabled: GridCommandToggle;
  addAttachmentDisabled: GridCommandToggle;
  deleteActionDisabled: GridCommandToggle;
  releaseToSAPDisabled: GridCommandToggle;
  sendBackFromTreasuryDisabled: GridCommandToggle;
  approvePreReviewDisabled?: GridCommandToggle;
  needsClarificationPreReviewDisabled?: GridCommandToggle;
  recallPreReviewDisabled?: GridCommandToggle;
  reverseDisabled?: GridCommandToggle;
  clearDisabled?: GridCommandToggle;
}

const initialState: IDashboardListActionsState = {
  retryDisabled: GridCommandToggle.Disabled,
  saveDisabled: GridCommandToggle.Disabled,
  sendToPosterDisabled: GridCommandToggle.Disabled,
  approveDisabled: GridCommandToggle.Disabled,
  releaseForSignoffDisabled: GridCommandToggle.Disabled,
  needsClarificationDisabled: GridCommandToggle.Disabled,
  addPosterDisabled: GridCommandToggle.Disabled,
  addReviewerDisabled: GridCommandToggle.Disabled,
  recallDisabled: GridCommandToggle.Disabled,
  rescindDisabled: GridCommandToggle.Disabled,
  addAttachmentDisabled: GridCommandToggle.Disabled,
  deleteActionDisabled: GridCommandToggle.Disabled,
  releaseToSAPDisabled: GridCommandToggle.Disabled,
  sendBackFromTreasuryDisabled: GridCommandToggle.Disabled,
  approvePreReviewDisabled: GridCommandToggle.Disabled,
  needsClarificationPreReviewDisabled: GridCommandToggle.Disabled,
  recallPreReviewDisabled: GridCommandToggle.Disabled,
  reverseDisabled: GridCommandToggle.Disabled,
  clearDisabled: GridCommandToggle.Disabled
};

function getButtonInitialSizes(divContainer: HTMLDivElement): Record<string, number> | null {
  const renderedCommandBarButtons = Array.from(divContainer.querySelectorAll(".ms-OverflowSet-item"));
  if (renderedCommandBarButtons.length === 0) {
    return null;
  }
  const buttonsWidth = renderedCommandBarButtons.reduce((buttons, buttonContainer) => {
    const theButton = buttonContainer.querySelector("button");
    if (!theButton) {
      return buttons;
    }

    const buttonName = theButton.getAttribute("name");
    if (!buttonName) {
      return buttons;
    }

    buttons[buttonName] = Math.floor(buttonContainer.getBoundingClientRect().width);

    return buttons;
  }, {} as Record<string, number>);

  return buttonsWidth;
}

const MAGIC_NUMBER_FOR_OVERFLOW = 64;

const DashboardListActions: React.FC<DashboardListActionsProps> = (props) => {
  const [flagStates, setFlagState] = useState<IDashboardListActionsState>(props.initialState || initialState);
  const containerRef = useRef<HTMLDivElement>(null);
  const [buttonsWidth, setButtonWidths] = useState<Record<string, number> | number>(0);

  const pageContext = useContext(PageContext);

  useImperativeHandle(props.customRef, () => ({
    refreshSelection<T>(items: T[]) {
      if (items.length === 0) {
        setFlagState(props.initialState || props.buttonCalculator([]));
      } else {
        setFlagState(() => {
          const buttonCalc = props.buttonCalculator(items);
          return { ...buttonCalc };
        });
      }
    }
  }));

  useEffect(() => {
    const initState = props.initialState || props.buttonCalculator([]);
    const fState = {
      retryDisabled: props.retryDisabled ? props.retryDisabled : initState.retryDisabled,
      saveDisabled: props.saveDisabled ? props.saveDisabled : initState.saveDisabled,
      sendToPosterDisabled: props.sendToPosterDisabled ? props.sendToPosterDisabled : initState.sendToPosterDisabled,
      approveDisabled: props.approveDisabled ? props.approveDisabled : initState.approveDisabled,
      releaseForSignoffDisabled: props.releaseForSignoffDisabled
        ? props.releaseForSignoffDisabled
        : initState.releaseForSignoffDisabled,
      needsClarificationDisabled: props.needsClarificationDisabled
        ? props.needsClarificationDisabled
        : initState.needsClarificationDisabled,
      addPosterDisabled: props.addPosterDisabled ? props.addPosterDisabled : initState.addPosterDisabled,
      addReviewerDisabled: props.addReviewerDisabled ? props.addReviewerDisabled : initState.addReviewerDisabled,
      recallDisabled: props.recallDisabled ? props.recallDisabled : initState.recallDisabled,
      rescindDisabled: props.rescindDisabled ? props.rescindDisabled : initState.rescindDisabled,
      addAttachmentDisabled: props.addAttachmentDisabled
        ? props.addAttachmentDisabled
        : initState.addAttachmentDisabled,
      deleteActionDisabled: props.deleteActionDisabled ? props.deleteActionDisabled : initState.deleteActionDisabled,
      releaseToSAPDisabled: props.releaseToSAPDisabled ? props.releaseToSAPDisabled : initState.releaseToSAPDisabled,
      sendBackFromTreasuryDisabled: props.sendBackFromTreasuryDisabled
        ? props.sendBackFromTreasuryDisabled
        : initState.sendBackFromTreasuryDisabled,
      approvePreReviewDisabled: props.approvePreReviewDisabled
        ? props.approvePreReviewDisabled
        : initState.approvePreReviewDisabled,
      needsClarificationPreReviewDisabled: props.needsClarificationPreReviewDisabled
        ? props.needsClarificationPreReviewDisabled
        : initState.needsClarificationPreReviewDisabled,
      recallPreReviewDisabled: props.recallPreReviewDisabled
        ? props.recallPreReviewDisabled
        : initState.recallPreReviewDisabled,
      reverseDisabled: props.reverseDisabled ? props.reverseDisabled : initState.reverseDisabled,
      clearDisabled: props.clearDisabled ? props.clearDisabled : initState.clearDisabled
    };
    setFlagState(fState);
  }, [props.initialState]);

  const recalculateButtons = debounce(() => {
    if (containerRef.current) {
      const notRemovedButtons = ObjectKeys(flagStates).filter((flag) => flagStates[flag] !== GridCommandToggle.Removed);
      if (ObjectKeys(buttonsWidth).length === notRemovedButtons.length) {
        // no need to recalculate the buttons
        return;
      }
      // This is an infinite loop
      // It stops when the buttonWidths is an object
      // But if the buttonWidths is a number it will keep looping
      const bWidths = getButtonInitialSizes(containerRef.current);
      if (bWidths === null) {
        return;
      }
      setButtonWidths((pWidth) => {
        if (isEqual(bWidths, pWidth) || ObjectKeys(pWidth).length > ObjectKeys(bWidths).length) return pWidth;
        return bWidths;
      });
    }
  }, 100);

  // NOTE:
  // The CommandBar doesn't work well (fluent 8.26) with Grid and Flex auto sizes.
  // Because of this it is unable to handle the "resize" of the page.
  // To fix this we need to manually calculate how many buttons should be visible
  // and how many should be hidden.
  // The following does the following:
  // 1. Get the initial sizes of ALL initial buttons, save it to a state (this will never change, so try to execute it once).
  useLayoutEffect(() => {
    if (containerRef.current) {
      setTimeout(recalculateButtons, 100);
    }
  }, [pageContext.dimensions, buttonsWidth, flagStates]);

  // 1. Calculate the size of the container of the buttons (using containerRef)
  // 2. Use these sizes to calculate how many buttons should be visible and how many should be hidden (getCommandBarButtons)
  let allButtons: CommandBarItems;
  if (containerRef.current && buttonsWidth && typeof buttonsWidth !== "number") {
    const mainContainerWidth =
      Math.floor(containerRef.current.getBoundingClientRect().width) - MAGIC_NUMBER_FOR_OVERFLOW;
    const optionsToResize: OptionsToResize = {
      mainContainerWidth,
      buttonsWidth
    };
    allButtons = getCommandBarButtons(flagStates, props, optionsToResize);
  } else {
    allButtons = getCommandBarButtons(flagStates, props);
  }
  return (
    <>
      {props.hide ? null : (
        <div
          ref={containerRef}
          className={css`
            width: 100%;
            min-height: 1px;
            box-sizing: content-box;
            overflow: hidden;
          `}
        >
          <CommandBar
            items={allButtons.items}
            overflowItems={allButtons.overFlowItems}
            styles={{
              root: {
                paddingLeft: 0
              }
            }}
            onReduceData={props.preventCollapse ? () => undefined : undefined}
            ariaLabel={"Actions to perform on selected Entries"}
            overflowButtonProps={{ ariaLabel: "More Actions" }}
          />
        </div>
      )}
    </>
  );
};

DashboardListActions.displayName = "DashboardListActions";

export { DashboardListActions };
